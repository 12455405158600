  <template>
  <div>
    <div class="note__header">
      <div class="note-date">{{ note.last_modification_date }}</div>
      <div>
        <div class="editing-buttons__container" v-if="isEditting">
          <a href="" class="save-button" @click.prevent="saveNote">
            <img src="@/assets/img/save_note.svg" alt="Save note" />
          </a>
          <a href="" class="delete-button" @click.prevent="deleteNote">
            <img src="@/assets/img/delete_note.svg" alt="Delete note"
          /></a>
        </div>
        <div v-else>
          <a href="" @click.prevent="startEdit">
            <img src="@/assets/img/Edit.svg" alt="Edit note" />
          </a>
        </div>
      </div>
    </div>
    <div>
      <template v-if="isEditting">
        <textarea class="note-textarea" v-model="text" type="text" />
      </template>
      <p class="note-text" v-else>{{ note.note }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: this.note.note,
      isEditting: false,
    };
  },
  watch: {
    note(note) {
      this.text = note.note;
    },
  },
  methods: {
    startEdit() {
      this.isEditting = true;
    },
    finishEdit() {
      this.isEditting = false;
    },
    saveNote() {
      if (this.note.note === this.text) {
        this.finishEdit();
        return;
      }

      this.$emit("updateNote", {
        ...this.note,
        note: this.text,
        onComplete: () => this.finishEdit(),
      });
    },
    deleteNote() {
      this.$emit("deleteNote", this.note.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.editing-buttons__container {
  * + * {
    margin-left: 1rem;
  }
}

.save-button,
.edit-button {
}
.note__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.note-date {
  color: $color-primary;

  @include tablet {
    color: $color-grey-light;
  }
}
.note-textarea {
  background: none;
  border: none;
  font-family: "helveticaneue";
  width: 100%;
  outline: none;
  font-size: 16px;
  letter-spacing: 1px;

  @include tablet {
    font-size: 22px;
  }
}

.note-text {
  margin: 0 0 1.5rem 0;
  @include mobile {
    font-size: 16px;
  }
}
</style>